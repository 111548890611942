<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card class="mb-8">
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 w-full">
            <h4>ใบแจ้งเตือนให้ชำระค่าน้ำ</h4>
          </div>
          <div class="vx-col sm:w-1/2 text-right w-full">
            <vs-button type="filled" color="primary" style="padding: .75rem 3rem" @click="submit()">แสดง</vs-button>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>เส้นทางจัดเก็บ:</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <v-select :options="areas" :clearable="false" v-model="selectedArea" class="mb-4 md:mb-0"/>
              </div>
            </div>
          </div>
          <div class="vx-col sm:w-1/2 xs:w-1/1 w-full">
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <span>จำนวนเดือนที่ค้างชำระ:</span>
              </div>
              <div class="vx-col sm:w-2/3">
                <vs-input class="w-full" type="number" v-model="month"/>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-1">
          <div class="vx-col sm:w-1/3">
             <vs-button color="warning" type="filled" @click="popupEdit=true">
               <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
               <span class="ml-2 text-base">แก้ไขใบเตือน</span>
             </vs-button>
            <vs-popup title="แก้ไขใบเตือน" :active.sync="popupEdit" class="edit-popup">
              <div class="content-popup pt-0">
                <div class="vx-row">
                  <div class="vx-col sm:w-1/2 w-full">
                    <vs-divider position="left">ข้อความในใบเตือน</vs-divider>
                    <div class="vx-row">
                      <div class="vx-col sm:w-1/1 w-full">
                        <vs-textarea v-model="longText" class="text-area"/>
                      </div>
                    </div>
                  </div>
                  <div class="vx-col sm:w-1/2 w-full">
                    <vs-divider position="right">ชื่อหน่วยงาน</vs-divider>
                    <div class="vx-row mb-3">
                      <div class="vx-col sm:w-1/4 w-full">
                        <span>ชื่อ/ที่อยู่1:</span>
                      </div>
                      <div class="vx-col sm:w-3/4">
                        <vs-input class="w-full" v-model="orgData.name1"/>
                      </div>
                    </div>
                    <div class="vx-row mb-3">
                      <div class="vx-col sm:w-1/4 w-full">
                        <span>ชื่อ/ที่อยู่2:</span>
                      </div>
                      <div class="vx-col sm:w-3/4">
                        <vs-input class="w-full" v-model="orgData.name2"/>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col sm:w-1/4 w-full">
                        <span>ชื่อ/ที่อยู่3:</span>
                      </div>
                      <div class="vx-col sm:w-3/4">
                        <vs-input class="w-full" v-model="orgData.name3"/>
                      </div>
                    </div>
                    <vs-divider position="right">ลงชื่อในใบเตือน</vs-divider>
                    <div class="vx-row mb-3">
                      <div class="vx-col sm:w-1/4 w-full">
                        <span>ลงชื่อผู้มีอำนาจ:</span>
                      </div>
                      <div class="vx-col sm:w-3/4">
                        <vs-input class="w-full" v-model="notice.name"/>
                      </div>
                    </div>
                    <div class="vx-row mb-3">
                      <div class="vx-col sm:w-1/4 w-full">
                        <span>ตำแหน่ง:</span>
                      </div>
                      <div class="vx-col sm:w-3/4">
                        <vs-input class="w-full" v-model="notice.position"/>
                      </div>
                    </div>
                    <div class="vx-row">
                      <div class="vx-col sm:w-1/4 w-full">
                        <span>เอกสารที่:</span>
                      </div>
                      <div class="vx-col sm:w-3/4">
                        <vs-input class="w-full" v-model="notice.no"/>
                      </div>
                    </div>
                    <vs-divider position="right">กอง</vs-divider>
                    <div class="vx-row mb-3">
                      <div class="vx-col sm:w-1/4 w-full">
                        <span>ข้อมูล1:</span>
                      </div>
                      <div class="vx-col sm:w-3/4">
                        <vs-input class="w-full" v-model="group.data1"/>
                      </div>
                    </div>
                    <div class="vx-row mb-3">
                      <div class="vx-col sm:w-1/4 w-full">
                        <span>ข้อมูล2:</span>
                      </div>
                      <div class="vx-col sm:w-3/4">
                        <vs-input class="w-full" v-model="group.data2"/>
                      </div>
                    </div>
                    <div class="vx-row mb-3">
                      <div class="vx-col sm:w-1/4 w-full">
                        <span>ข้อมูล3:</span>
                      </div>
                      <div class="vx-col sm:w-3/4">
                        <vs-input class="w-full" v-model="group.data3"/>
                      </div>
                    </div>
                    <div class="vx-row mb-5">
                      <div class="vx-col sm:w-1/4 w-full">
                        <span>ข้อมูล4:</span>
                      </div>
                      <div class="vx-col sm:w-3/4">
                        <vs-input class="w-full" v-model="group.data4"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer-popup vx-row mt-2">
                <div class="vx-col sm:w-1/2 text-right w-full">
                  <vs-button @click="cancelPopup()" class="btn-close-popup" color="danger" type="filled">ยกเลิก</vs-button>
                </div>
                <div class="vx-col sm:w-1/2 w-full">
                  <vs-button @click="editPopup()" color="primary" type="filled">บันทึก</vs-button>
                </div>
              </div>
            </vs-popup>
          </div>
          <div class="vx-col sm:w-2/3 text-right w-full">
            <PDFListReport :users="users" :sum="sum" :config="configData" :area="selectedArea" :month="month"/>
            <PDFFirstPaymentReport class="ml-3" :orgData="orgData" :notice="notice" :group="group" :users="users"/>
            <PDFPaymentReport class="ml-3" :longText="longText" :orgData="orgData" :notice="notice" :group="group" :area="selectedArea" :users="users" :imgSignBase64="imgSignBase64"/>
          </div>
        </div>
      </vx-card>
      <vs-table noDataText="ไม่พบข้อมูล" :data="users" class="table-custom">
        <template slot="thead">
          <vs-th>ลำดับที่</vs-th>
          <vs-th>รหัสผู้ใช้น้ำ</vs-th>
          <vs-th>ชื่อสกุล</vs-th>
          <vs-th>จำนวนที่ค้าง</vs-th>
          <vs-th>เป็นเงิน</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium">{{ indextr + 1 }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.member_id }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.member_name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ tr.book_lists.length }}</p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium">{{ sum[indextr] }}</p>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>


</template>

<script>

  import vSelect from "vue-select";
  import PDFListReport from "../pdf/ListPaymentNoticeReport.vue";
  import PDFPaymentReport from "../pdf/PaymentNoticeReport.vue";
  import PDFFirstPaymentReport from "../pdf/FirstPaymentNoticeReport.vue";
  import moduleArea from "@/store/area/moduleArea.js";
  import moduleBookList from "@/store/booklist/moduleBookList.js";
  import moduleConfig from "@/store/config/moduleConfig.js"

  export default {
    components: {
      vSelect,
      PDFListReport,
      PDFPaymentReport,
      PDFFirstPaymentReport
    },
    data() {
      return {
        selectedArea: {label: '', value: ''},
        month: 4,
        users: [],
        popupEdit: false,
        textarea: '',
        orgData: {
          name1: '',
          name2: '',
          name3: ''
        },
        notice: {
          name: '',
          position: '',
          no: ''
        },
        group: {
          data1: '',
          data2: '',
          data3: '',
          data4: ''
        },
        longText: '',
        localData: {},
        sum: [],
        baseUrl: 'http://188.166.177.25/',
        imgSignBase64: ''
      }
    },
    computed: {
      configData() {
        return this.$store.state.config.item;
      },
      areas() {
        let options = [];
        this.$store.state.area.items.forEach(function (item) {
          options.push({
            label: item.A02,
            value: item.A01
          });
        });
        return options;
      },
    },
    methods: {
      async init() {
        const area = await this.$store.state.area.items[0];
        this.selectedArea = {
          label: area.A02,
          value: area.A01
        };
        this.localData = JSON.parse(localStorage.getItem('dataNotice'));
        if(this.localData !== null && this.localData !== '' && this.localData !== undefined) {
          this.orgData = this.localData.orgData;
          this.notice = this.localData.notice;
          this.group = this.localData.group;
          this.longText = this.localData.longText;
        }
        const payload = {
          M02: area.A01,
          bill_amount: this.month,
          B10: '0'
        };
        const url = await this.$store.state.config.item.warning_image_url;
        if(url !== '' && url !== undefined && url !== null) {
          this.imgSignBase64 = await this.getDataUri(this.baseUrl + url);
        }
        this.getBookLists(payload);
      },
      getDataUri(url) {
        return new Promise(resolve => {
          let image = new Image();
          image.setAttribute('crossOrigin', 'anonymous');
          image.onload = function () {
            let canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(this, 0, 0);
            let dataURL = canvas.toDataURL("image/png");
            resolve(canvas.toDataURL("image/png"));
          };
          image.src = url;
        })
      },
      async getBookLists(payload) {
        await this.$store.dispatch("bookLists/getBookListsWarning", payload).then((res) => {
          if (res.status === 200) {
            this.users = res.data;
            let arr = [];
            for (let i = 0; i < this.users.length; i++) {
              let sumBA = 0;
              for (let j = 0; j < this.users[i].book_lists.length; j++ ) {
                const data =  this.users[i].book_lists[j];
                let BA11 = 0;
                let BA12 = 0;
                let BA13 = 0;
                let BA14 = 0;
                let BA15 = 0;
                if (data.BA11 !== undefined && data.BA11 !== null && data.BA11 !== '' && data.BA11 !== 'NaN') {
                  BA11 = parseFloat(data.BA11);
                }
                if (data.BA12 !== undefined && data.BA12 !== null && data.BA12 !== '' && data.BA12 !== 'NaN') {
                  BA12 = parseFloat(data.BA12);
                }
                if (data.BA13 !== undefined && data.BA13 !== null && data.BA13 !== '' && data.BA13 !== 'NaN') {
                  BA13 = parseFloat(data.BA13);
                }
                if (data.BA14 !== undefined && data.BA14 !== null && data.BA14 !== '' && data.BA14 !== 'NaN') {
                  BA14 = parseFloat(data.BA14);
                }
                if (data.BA15 !== undefined && data.BA15 !== null && data.BA15 !== '' && data.BA15 !== 'NaN') {
                  BA15 = parseFloat(data.BA15);
                }
                sumBA += BA11 + BA12 + BA13 + BA14 + BA15;
              }
              arr.push(sumBA.toFixed(2))
            }
            this.sum = arr;
            this.$vs.loading.close();
          } else  {
            this.users = [];
            this.sum = [];
            this.$vs.loading.close();
          }
        }).catch((error) => {
          this.users = [];
          this.sum = [];
          this.$vs.loading.close();
        });
      },
      submit() {
        if (this.month === '') {
          this.warningDialog('กรุณากรอกจำนวนเดือนที่ค้างชำระ');
          return;
        }
        this.$vs.loading();
        const payload = {
          M02: this.selectedArea.value,
          bill_amount: this.month
        };
        this.getBookLists(payload);
      },
      editPopup() {
        const dataNotice = {
          orgData: this.orgData,
          notice: this.notice,
          group: this.group,
          longText: this.longText
        };
        localStorage.setItem('dataNotice', JSON.stringify(dataNotice));
        this.popupEdit = false;
      },
      cancelPopup(){
        this.orgData = {
          name1: '',
          name2: '',
          name3: ''
        };
        this.notice = {
          name: '',
          position: '',
          no: ''
        };
        this.group = {
          data1: '',
          data2: '',
          data3: '',
          data4: ''
        };
        this.longText = '';
        this.popupEdit = false;
      },
      warningDialog(message) {
        this.$vs.dialog({
          type: 'alert',
          color: 'warning',
          title: `คำเตือน`,
          text: message,
          acceptText: 'ปิด'
        })
      },
    },
    async created() {
      if (!moduleBookList.isRegistered) {
        this.$store.registerModule('bookLists', moduleBookList);
        moduleBookList.isRegistered = true;
      }
      if (!moduleArea.isRegistered) {
        this.$store.registerModule('area', moduleArea);
        moduleArea.isRegistered = true
      }
      if (!moduleConfig.isRegistered) {
        this.$store.registerModule('config', moduleConfig);
        moduleConfig.isRegistered = true
      }
      await this.$store.dispatch("config/fetchDataItem");
      await this.$store.dispatch("area/fetchDataListItems");
      this.init();
    },
  }

</script>

<style lang="scss">
  .table-custom .vs-con-tbody .vs-table--tbody-table .vs-table--thead th {
    background: #8794a2;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
  }
  .edit-popup .vs-popup {
    width: 1000px;
  }
  .edit-popup  .vs-popup--content {
    padding-top: 0;
  }
  .text-area {
    height: 545px;
    overflow-y: scroll
  }
  .text-area textarea {
    height: 100%;
  }
  </style>
