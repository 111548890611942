<template>
  <vs-button @click="generatePDF()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์หน้าซอง</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'
  const fs = require('fs');
  import { font } from "./assets/THSarabunNew-normal";
  import { font_bold } from "./assets/THSarabunNew-bold";
  import { logo_img } from "./assets/logoGurada";
  export default {
    components: {
      jsPDF,
    },
    name: "CheckMeterForm",
    props: ['orgData', 'notice', 'group', 'users'],
    data() {
      return {

      }
    },
    methods: {
      generatePDF() {
        const doc = new jsPDF({format: [279, 210], unit:'mm', orientation: 'portrait',});
        const orgData = this.orgData;
        const notice = this.notice;
        const group = this.group;
        const users = this.users;

        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        for (let i = 0; i < users.length; i++) {
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(14);
          doc.text(orgData.name2, 10, 10);
          doc.text(orgData.name3, 10, 18);
          doc.text(notice.no, 13, 25);
          doc.setFont('THSarabunNew', 'bold');
          doc.text('ที่...........................................', 10, 26);
          doc.text('เรียน', 80, 34);
          doc.text('ป.', 165, 34);
          doc.setFont('THSarabunNew', 'normal');
          doc.text(users[i].member_name, 90, 34);
          doc.text(users[i].member_id, 170, 34);
          doc.text(users[i].member_address_no + ' ' + users[i].member_soi + ' ' + users[i].member_road + ' ' + users[i].member_address, 90, 42);
          if (i < users.length - 1) {
            doc.addPage();
          }
        }
        doc.save('หน้าซองใบเตือน' + moment().format('DD-MM-YYYY') + '.pdf');
      }
    }
  }
</script>
