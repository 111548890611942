<template>
  <vs-button @click="generatePDF()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์ใบเตือน</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'

  const fs = require('fs');
  import {font} from "./assets/THSarabunNew-normal";
  import {font_bold} from "./assets/THSarabunNew-bold";
  import {logo_img} from "./assets/logoGurada";
  import moment from "moment";
  import {formatNumber2Digit, formatNumber, formatFloat} from "./assets/utils";

  export default {
    components: {
      jsPDF,
    },
    name: "CheckMeterForm",
    props: ['longText', 'orgData', 'notice', 'group', 'area', 'users', 'imgSignBase64'],
    data() {
      return {
        thmonth: ['', 'มกราคม', 'กุมภาพันธ์', 'มีนาคม',
          'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน',
          'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      }
    },
    methods: {
      generatePDF() {
        const doc = new jsPDF({format: [279, 210], unit: 'mm', orientation: 'portrait',});
        const users = this.users;
        const signature = this.imgSignBase64;
        // var splitTitle = doc.splitTextToSize(articles, 310);

        const space = '                                       ';
        const longText = this.longText;
        const orgData = this.orgData;
        const notice = this.notice;
        const group = this.group;
        const area = this.area;
        const dateNow = moment().format('DD/MM/YYYY');
        const dateSplit = dateNow.split('/');
        const year = parseInt(dateSplit[2]) + 543;
        const date = dateSplit[0] + ' ' + this.thmonth[parseInt(dateSplit[1])] + ' ' + year;

        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        for (let i = 0; i < this.users.length; i++) {
          doc.addImage(logo_img, 'PNG', 94, 15, 22, 25, {align: 'center'});
          doc.setFont('THSarabunNew', 'normal');
          doc.setFontSize(14);
          doc.text('ที่   ' + notice.no, 25.4, 30);
          doc.text(orgData.name2, 140, 30);
          doc.text(orgData.name3, 140, 36);
          doc.text(date, doc.internal.pageSize.getWidth() / 2, 52);
          doc.text('เรื่อง   แจ้งเตือนขอให้ชำระเงินค่าน้ำประปา', 25.4, 65);
          doc.text('เรียน   ' + users[i].member_name + '   ผู้ใช้น้ำเลขที่ ' + users[i].member_id + ' ' + area.label, 25.4, 73);
          doc.text('ที่อยู่   ' + users[i].member_address_no + ' ' + users[i].member_soi + ' ' + users[i].member_road + ' ' + users[i].member_address, 25.4, 81);
          doc.setFont('THSarabunNew', 'normal');
          let arr = [];
          let water = 0;
          let vat = 0;
          let special = 0;
          let sum = 0;
          for (let j = 0; j < users[i].book_lists.length; j++) {
            const data = users[i].book_lists[j];
            let BA11 = 0;
            let BA12 = 0;
            let BA13 = 0;
            let BA14 = 0;
            let BA15 = 0;
            let sumBA = 0;
            if (data.BA11 !== undefined && data.BA11 !== null && data.BA11 !== '' && data.BA11 !== 'NaN') {
              BA11 = parseFloat(data.BA11);
            }
            if (data.BA12 !== undefined && data.BA12 !== null && data.BA12 !== '' && data.BA12 !== 'NaN') {
              BA12 = parseFloat(data.BA12);
            }
            if (data.BA13 !== undefined && data.BA13 !== null && data.BA13 !== '' && data.BA13 !== 'NaN') {
              BA13 = parseFloat(data.BA13);
            }
            if (data.BA14 !== undefined && data.BA14 !== null && data.BA14 !== '' && data.BA14 !== 'NaN') {
              BA14 = parseFloat(data.BA14);
            }
            if (data.BA15 !== undefined && data.BA15 !== null && data.BA15 !== '' && data.BA15 !== 'NaN') {
              BA15 = parseFloat(data.BA15);
            }
            sumBA = BA11 + BA12 + BA13 + BA14 + BA15;
            arr.push([
              j + 1,
              data.B22,
              data.CB04,
              data.B18,
              data.B20,
              data.B21,
              formatNumber2Digit(BA11),
              formatNumber2Digit(BA12),
              formatNumber2Digit(BA14),
              formatNumber2Digit(BA13),
              formatNumber(sumBA)
            ]);
            water += BA11;
            vat += BA13;
            special += BA12;
            sum += formatFloat(sumBA);
          }
          arr.push([
            {content: ' ', colSpan: 6},
            {content: formatNumber(water), styles: { fontSize: 13}},
            {content: formatNumber(special), styles: { fontSize: 13}},
            {content: formatNumber(vat), styles: { fontSize: 13}},
            {content: formatNumber(sum), styles: { fontSize: 13}}
          ]);
          doc.autoTable({
            styles: {font: 'THSarabunNew', fontSize: 14},
            startY: 85,
            margin: {left: 8},
            theme: 'plain',
            body: [
              [space + orgData.name1 + ' ขอส่ง'+group.data4+'ค่าน้ำประปาของท่านตามรายละเอียดดังต่อไปนี้']
            ]
          });
          doc.autoTable({
            styles: {font: 'THSarabunNew', fontSize: 11},
            margin: {left: 8, right: 8},
            theme: 'plain',
            didDrawCell: function (data) {
              if (data.section === 'body' && data.column.index === 0 && data.row.index === users[i].book_lists.length) {
                doc.text('__________________________________________________', 130, data.cell.y + 7)
              }
            },
            head: [['ลำดับที่', 'เลขที่ใบแจ้งหนี้', 'ประจำเดือน', 'เลขอ่านครั้งก่อน', 'เลขอ่านครั้งหลัง', 'จำนวนหน่วย', 'ค่าน้ำ', 'ค่าธรรมเนียม', 'ค่าบำบัด', 'ภาษี', 'จำนวนเงิน']],
            body: arr
          });
          doc.autoTable({
            styles: {font: 'THSarabunNew', fontSize: 14},
            margin: {left: 8},
            theme: 'plain',
            body: [
              [space + longText]
            ]
          });
          doc.autoTable({
            styles: {font: 'THSarabunNew', fontSize: 14},
            margin: {left: doc.internal.pageSize.getWidth() / 2},
            theme: 'plain',
            didDrawCell: function (data) {
              if (data.section === 'body' && data.column.index === 0 && data.row.index === 1) {
                const td = data.cell.raw;
                const textPos = data.cell.textPos;
                doc.addImage(signature, 'JPEG', (doc.internal.pageSize.getWidth() / 2) + 5, data.cell.y, 20, 10)
              }
            },
            body: [
              [{content: 'ขอแสดงความนับถือ'}],
              [' '],
              [' '],
              [{content: notice.name}],
              [{content: notice.position}],
            ]
          });
          doc.autoTable({
            styles: {font: 'THSarabunNew', fontSize: 14},
            margin: {left: 8, bottom: 8},
            theme: 'plain',
            body: [
              [group.data1],
              [group.data2]
            ]
          });
          if (i < this.users.length - 1) {
            doc.addPage();
          }
        }
        doc.save('ใบเตือน' + moment().format('DD-MM-YYYY') + '.pdf');
      }
    }
  }
</script>
