<template>
  <vs-button @click="generatePDF()" color="primary" type="border" class="mb-4 md:mb-0">พิมพ์รายการ</vs-button>
</template>

<script>
  import jsPDF from "jspdf";
  import 'jspdf-autotable'
  const fs = require('fs');
  import { font } from "./assets/THSarabunNew-normal";
  import { font_bold } from "./assets/THSarabunNew-bold";
  import moment from "moment";

  export default {
    components: {
      jsPDF,
    },
    props: ['users', 'sum', 'config', 'area', 'month'],
    data() {
      return {

      }
    },
    methods: {
      generatePDF() {
        const doc = new jsPDF({format: [279, 210], unit:'mm', orientation: 'portrait',});
        const users = this.users;
        const sum = this.sum;
        const config = this.config;
        const month = this.month;
        const area = this.area;

        const date_print = this.date_print;
        let array = [];
        for (let i = 0; i < users.length; i++) {
          array.push([
            i + 1,
            users[i].member_id,
            users[i].member_name,
            users[i].member_address_no,
            users[i].member_meter_no,
            users[i].book_lists.length,
            sum[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ]);
        }
        doc.addFileToVFS('THSarabunNew-normal.ttf', font);
        doc.addFileToVFS('THSarabunNew-bold.ttf', font_bold);
        doc.addFont('THSarabunNew-normal.ttf', 'THSarabunNew', 'normal');
        doc.addFont('THSarabunNew-bold.ttf', 'THSarabunNew', 'bold');
        doc.autoTable({
          styles: { font: 'THSarabunNew', fontSize: 14 },
          tableWidth: 198,
          margin: { left: 8, top: 45, bottom: 10},
          theme: 'plain',
          head: [['ลำดับที่', 'รหัสผู้ใช้น้ำ', 'ชื่อ - สกุล', 'บ้านเลขที่', 'เลขมิเตอร์น้ำ', 'ค้าง(ด)', 'เป็นเงิน']],
          body: array,
          didDrawPage: function (data) {
            // Header
            doc.setFont('THSarabunNew','bold');
            doc.setFontSize(18);
            doc.text(config.Org01, doc.internal.pageSize.getWidth()/2, 10, {align: 'center'});
            doc.text(config.Org03, doc.internal.pageSize.getWidth()/2, 18, {align: 'center'});
            doc.setFontSize(15);
            doc.text('รายงานผู้ได้รับใบเตือนค้างชำระตั้งแต่ ' + month + ' เดือน ในเส้นทางจัดเก็บที่ ' + area.label, doc.internal.pageSize.getWidth()/2, 28, {align: 'center'});
            doc.setFont('THSarabunNew','normal');
            doc.setFontSize(14);
            doc.text(moment().format('DD/MM/YYYY'), 10, 40);
            // doc.text(page, 200, 40, {align: 'right'});
          }
        });
        doc.save('รายงานชำระหนี้(หน้าใบเตือน)'+ moment().format('DD-MM-YYYY') + '.pdf');
      }
    }
  }
</script>
